import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import APIConsultas from "../../services/consultas";
import LoadingButton from "../utils/loadingButton";

const EditarLocalidad = ({ edit, openModal, setOpenModal }) => {
  const [newLoc, setNewLoc] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("editar localidades");

    setNewLoc(edit);
  }, [edit]);

  const onChange = async (e) => {
    setNewLoc({
      ...newLoc,
      [e.target.name]: e.target.value,
    });
  };
  const createLocalidad = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await APIConsultas.localidad.UPD_LOC(newLoc);
    if (response.affectedRows > 0) {
      toast.success("Campo actualizado!");
      setLoading(false);
      setOpenModal(false);
    }
  };

  return (
    <div>
      {openModal && (
        <div
          id="authentication-modal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
          aria-modal="true"
          role="dialog"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                data-modal-toggle="authentication-modal"
                onClick={() => setOpenModal(false)}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 ">
                  Editar Localidad
                </h3>
                <form className="space-y-6" onSubmit={createLocalidad}>
                  <div>
                    <label
                      htmlFor="nombre"
                      className="block text-start  mb-2 text-sm font-medium text-gray-900 "
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="nombre"
                      id="nombre"
                      value={newLoc.nombre}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                      placeholder="Ingrese nombre..."
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="direccion"
                      className="block text-start  mb-2 text-sm font-medium text-gray-900 "
                    >
                      Direccion
                    </label>
                    <input
                      type="text"
                      name="dircontacto"
                      id="direccion"
                      value={newLoc.dircontacto}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                      placeholder="Ingrese direccion..."
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="telcontacto"
                      className="block mb-2 text-start  text-sm font-medium text-gray-900 "
                    >
                      Telefono
                    </label>
                    <input
                      type="text"
                      name="telcontacto"
                      id="telcontacto"
                      value={newLoc.telcontacto}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                      placeholder="Ingrese telefono..."
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div>
                    <LoadingButton
                      onClick={() => null}
                      title="Guardar"
                      id="buttonEditarLocalidad"
                      type="submit"
                      loading={loading}
                    />
                  </div>
                  {/* <button
                    type="submit"
                    className="w-full text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center   "
                    onClick={(e) => createLocalidad(e)}
                  >
                    Guardar
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default EditarLocalidad;
