import React, { useEffect, useState } from "react";
import {
  GridActionsCellItem,
} from "@mui/x-data-grid";
import CrearLocalidad from "./CrearLocalidad";
import APIConsultas from "../../services/consultas";
import EditarLocalidad from "./EditarLocalidad";
import GridApp from "../gridApp";

const Localidades = () => {
  const [rows_edif, setRows_edif] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const columns = [
    { field: "nombre", headerName: "nombre", flex: 1 },
    { field: "dircontacto", headerName: "Direccion Contacto", flex: 1.5 },
    { field: "telcontacto", headerName: "Telefono Contacto", flex: 0.5 },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          key={1}
          onClick={(e) => consol(e, params.row)}
          icon={
            <span className="material-icons-outlined text-green-500">draw</span>
          }
          label="Editar"
        />,
      ],
    },
  ];
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await APIConsultas.localidad.GET_TODO();
      setRows_edif(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const consol = (e, params) => {
    e.preventDefault();
    setOpenModal(true);
    setEdit(params);
  };

  return (
    <div className="px-[5rem] pb-8">
      <div className="flex justify-end mb-3"></div>

      <div className="flex justify-between items-center">
        <h1 className="font-bold uppercase text-primary-500 text-2xl text-start my-4">
          Localidades
        </h1>
        <EditarLocalidad
          edit={edit}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
        <CrearLocalidad />
      </div>

      <GridApp
        rows={rows_edif}
        columns={columns}
        loading={loading}
        useToolbar={true}
        rowId={"idloc"}
      />
    </div>
  );
};

export default Localidades;