import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import APIConsultas from "../../services/consultas";

const FormLogo = ({ id, logo, setLogo }) => {
  const [loading, setLoading] = useState(false);

  // console.log(logo);
  const onFileChange = () => (ev) => {
    if (!ev.target.files) return;
    const arrfiles = ev.target.files;

    for (const file in arrfiles) {
      if ((file.size / 1024).toFixed(2) > 200) {
        return toast.error(`Imagen demasiado pesada.`);
      }
      if (Object.prototype.hasOwnProperty.call(arrfiles, file)) {
        const element = ev.target.files[file];
        const reader = new FileReader();
        reader.onload = (event1) => {
          if (file !== "length" && file !== "item") {
            const obj = {
              titulo: "",
              archivo: event1.target.result,
              url: event1.target.result,
              indice: "",
              progress: 0,
              descripcion: "",
            };

            const arrim = [logo];
            setLogo(arrim);
            return setImageServer(obj, element);
          }
        };
        if (file !== "length" && file !== "item") {
          reader.readAsDataURL(ev.target.files[file]);
        }
      }
    }

    async function setImageServer(file, element) {
      const obj = {
        index: "add_file",
        file: file.archivo,
        filename: "logo.png",
        path: `/GF/File/proyects/${id}/`,
      };
      try {
        const response = await APIConsultas.edificio.imagenes.ADD_LOGO(obj);
        if (response) {
          setLoading(false);
          return toast.success(`Dato actualizado!`, {
            autoClose: 1000,
          });
        } else {
          return toast.error(`Error al agregar imagen.`);
        }
      } catch (error) {
        console.error(error);
      }

      return toast.error(`Error al agregar imagen.`);
    }
  };

  return (
    <div className="w-[50%]">
      <h1 className="font-bold">Logo</h1>
      <div className="border-2 border-grey rounded-lg p-1 ">
        <div className="btn-addimg flex justify-center relative cursor-pointer ">
          <label
            className="btnimage block h-6 w-6 absolute right-4 top-1"
            title="Cambiar imagen logo"
            id="logo"
          >
            <i className="bx bx-image-add  text-2xl text-blue-500 hover:text-blue-700"></i>
            <input
              type="file"
              accept="image/*"
              name="Imgcarga"
              onChange={onFileChange()}
              multiple
              id="logo"
              className="hidden"
            />
          </label>
        </div>
        <div className="overflow-x-auto min-h-[56px] max-h-[345px] w-full mt-[2rem] flex  items-center border-transparent border-l-2 relative">
          <div className="flex flex-col w-[100%]">
            {loading && (
              <div className="absolute top-[-3rem] left-3 right-0 bottom-0 m-auto flex justify-center items-center">
                <i className="bx bx-loader bx-spin text-2xl"></i>
                <p>Cargando Logo..</p>
              </div>
            )}
            {logo &&
              logo.map((img, indeximg) => (
                <div
                  key={indeximg}
                  className="flex  justify-between items-center p-1   hover:bg-primary-100 cursor-pointer"
                >
                  {console.log(img)}
                  <img
                    src={img}
                    alt="A"
                    priority="false"
                    className="rounded w-[70px] h-[70px] bg-primary-500"
                  />
                  <p>logo.png</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default FormLogo;
