import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import APIConsultas from "../../services/consultas";

const FormAvances = ({ id, arrAvances, setArrAvances }) => {
  const [cortes, setCortes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const nombres = [];
    let corte = "";
    if (arrAvances) {
      arrAvances.forEach((url) => {
        if (url.archivo) {
          corte = url.archivo.split("/");
        } else {
          corte = url.split("/");
        }
        nombres.push(corte[8]);
      });
      setCortes(nombres);
    }
  }, [arrAvances]);

  const deleteImage = async (img, indeximg) => {
    Swal.fire({
      title: "Desea eliminar la imagen ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await APIConsultas.edificio.imagenes.DELETE_AVANCES(
          id,
          img
        );
        if (response) {
          const arrim = [...arrAvances];
          arrim.splice(indeximg, 1);
          setArrAvances(arrim);
          return toast.success(`Dato actualizado!`, {
            autoClose: 1000,
          });
        }
        return toast.error(`Error al eliminar imagen`);
      }
    });
  };
  const onFileChange = () => (ev) => {
    setLoading(true);

    if (!ev.target.files) return;
    const arrfiles = ev.target.files;

    for (const file in arrfiles) {
      if ((file.size / 1024).toFixed(2) > 200) {
        return toast.error(`Imagen demasiado pesada.`);
      }
      if (Object.prototype.hasOwnProperty.call(arrfiles, file)) {
        const element = ev.target.files[file];
        const reader = new FileReader();
        reader.onload = (event1) => {
          if (file !== "length" && file !== "item") {
            const obj = {
              titulo: "",
              archivo: event1.target.result,
              url: event1.target.result,
              indice: "",
              progress: 0,
              descripcion: "",
            };

            const arrim = [...arrAvances];
            arrim.unshift(obj);
            setArrAvances(arrim);
            return setImageServer(obj, element);
          }
        };
        if (file !== "length" && file !== "item") {
          reader.readAsDataURL(ev.target.files[file]);
        }
      }
    }

    async function setImageServer(file, element) {
      const obj = {
        index: "add_file",
        file: file.archivo,
        filename: element.name,
        path: `/GF/File/proyects/${id}/avances/`,
      };
      try {
        const response = await APIConsultas.edificio.imagenes.ADD_AVANCES(obj);
        if (response) {
          setLoading(false);

          return toast.success(`Dato actualizado!`, {
            autoClose: 1000,
          });
        } else {
          return toast.error(`Error al agregar imagen.`);
        }
      } catch (error) {
        console.error(error);
      }

      return toast.error(`Error al agregar imagen.`);
    }
  };

  return (
    <div className="w-[50%]">
      <h1 className="font-bold">Avances web</h1>
      <div className="border-2 border-grey rounded-lg p-1 ">
        <div className="btn-addimg flex justify-center relative cursor-pointer ">
          <label
            className="btnimage block h-6 w-auto absolute right-4 top-1"
            title="Agregar imagen"
            id="avancesWeb"
          >
            <div className="flex items-center gap-1">
              <span className="text-[12px] text-red-700">Ancho max.: 1620</span>
              <i className="bx bx-image-add  text-2xl text-blue-500 hover:text-blue-700"></i>
            </div>
            <input
              type="file"
              accept="image/*"
              name="Imgcarga"
              onChange={onFileChange()}
              multiple
              className="hidden"
              id="avancesWeb"
            />
          </label>
        </div>
        <div className="overflow-x-auto min-h-[56px] overflow-y-scroll  scrollbar-1 scrollbar-thumb-gray-500 scrollbar-track-gray-100 max-h-[300px] w-full mt-[3rem] flex  items-center border-transparent border-l-2 relative">
          <div className="flex flex-col w-[100%]">
            {loading && (
              <div className="absolute top-[-3rem] left-3 right-0 bottom-0 m-auto flex justify-center items-center">
                <i className="bx bx-loader bx-spin text-2xl"></i>
                <p>Cargando avances..</p>
              </div>
            )}

            {arrAvances &&
              arrAvances.map((img, indeximg) => (
                <div
                  key={indeximg}
                  className="flex  justify-between items-center p-1   hover:bg-primary-100 cursor-pointer"
                >
                  <img
                    src={img}
                    alt="A"
                    priority="false"
                    className="rounded w-[100px] h-[50px]"
                  />
                  <span>{cortes[indeximg]}</span>
                  <span
                    aria-hidden
                    className="material-icons-outlined z-10  top-[2px] right-[5px] text-sm cursor-pointer text-red-600"
                    title="Eliminar imagen "
                    onClick={() => deleteImage(img, indeximg)}
                  >
                    close
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default FormAvances;
