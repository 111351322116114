const {
  REACT_APP_API_URL,
  REACT_APP_API_INDEXDB,
  REACT_APP_API_AUTHORIZATION,
  REACT_APP_API_URL_IMAGES,
} = process.env;

const postData = async (path, data) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: REACT_APP_API_AUTHORIZATION,
      dbindex: REACT_APP_API_INDEXDB,
    },
    body: JSON.stringify(data),
  };
  const url = `${REACT_APP_API_URL}${path}`;
  const re = await fetch(url, options);
  if (re.ok) {
    return await re.json();
  }
  return false;
};
const getData = async (path) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: REACT_APP_API_AUTHORIZATION,
      dbindex: REACT_APP_API_INDEXDB,
    },
  };
  const url = `${REACT_APP_API_URL}${path}`;
  const re = await fetch(url, options);
  if (re.ok) {
    return await re.json();
  }
  return false;
};
const putData = async (path, data) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: REACT_APP_API_AUTHORIZATION,
      dbindex: REACT_APP_API_INDEXDB,
    },
    body: JSON.stringify(data),
  };
  const url = `${REACT_APP_API_URL}${path}`;
  const re = await fetch(url, options);
  if (re) {
    return await re.json();
  }
  return false;
};
const deleteData = async (path) => {
  const options = {
    method: "DELETE",
    headers: {
      Authorization: REACT_APP_API_AUTHORIZATION,
      dbindex: REACT_APP_API_INDEXDB,
    },
  };
  const url = `${REACT_APP_API_URL}${path}`;
  const re = await fetch(url, options);
  if (re.ok) {
    return await re.json();
  }
  return false;
};

const APIConsultas = {
  usuario: {
    SESSION_CONTROL: async () => {
      try {
        const response = await getData(`/api/usuarios/ctrlSession`);
        if (response) return response;
      } catch (error) {
        console.warn(error);
        return [];
      }
    },
    LOGIN: async (user, password) => {
      try {
        const response = await postData(`/api/usuarios/login`, {
          user,
          password,
        });
        if (response.user.length === 0) return null;
        return response.user[0];
      } catch (error) {
        console.warn(error);
        return false;
      }
    },
  },
  edificio: {
    ADD: async (datosEdif) => {
      try {
        const response = await postData(`/api/edificios`, datosEdif);
        return response;
      } catch (error) {
        console.warn(error);
        return false;
      }
    },
    DELETE: async (id) => {
      try {
        const response = deleteData(`/api/edificios/${id}}`);
        return response;
      } catch (error) {
        console.warn(error);
        return false;
      }
    },
    UPDATE_XCAMPO: async (obj) => {
      try {
        const response = await putData(`/api/edificios`, obj);
        return response;
      } catch (error) {
        return false;
      }
    },
    GET_TODO: async (limit) => {
      try {
        const response = await getData(`/api/edificios?limit=${limit}`);
        if (response) return response;
      } catch (error) {
        console.warn(error);
        return [];
      }
    },
    GET_XID: async (id) => {
      try {
        const response = await getData(`/api/edificios/${id}`);
        if (response) return response;
      } catch (error) {
        console.warn(error);
        return null;
      }
    },
    imagenes: {
      GET_AVANCES: async (id) => {
        try {
          const response = await getData(`/api/imagenes/renders/${id}/avances`);
          if (response) return response;
        } catch (error) {
          console.warn(error);
          return [];
        }
      },
      GET_RENDERS: async (id) => {
        try {
          const response = await getData(
            `/api/imagenes/renders/${id}/rendersweb`
          );
          if (response) return response;
        } catch (error) {
          console.warn(error);
          return [];
        }
      },
      GET_LOGO: async (id) => {
        try {
          const response = await getData(`/api/imagenes/media/${id}/logo`);
          if (response) return response;
        } catch (error) {
          console.warn(error);
          return [];
        }
      },
      GET_PORTADA: async (id) => {
        try {
          const response = await getData(`/api/imagenes/media/${id}/fondo`);
          if (response) return response;
        } catch (error) {
          console.warn(error);
          return [];
        }
      },
      ADD_AVANCES: async (obj) => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        };
        try {
          return await fetch(REACT_APP_API_URL_IMAGES, options);
        } catch (error) {
          console.warn(error);
        }
      },
      ADD_RENDERS: async (obj) => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        };
        try {
          return await fetch(REACT_APP_API_URL_IMAGES, options);
        } catch (error) {
          console.warn(error);
        }
      },
      ADD_LOGO: async (obj) => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        };
        try {
          return await fetch(REACT_APP_API_URL_IMAGES, options);
        } catch (error) {
          console.warn(error);
        }
      },
      ADD_PORTADA: async (obj) => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        };
        try {
          return await fetch(REACT_APP_API_URL_IMAGES, options);
        } catch (error) {
          console.warn(error);
        }
      },
      DELETE_AVANCES: async (id, img) => {
        try {
          const re = await putData(`/api/imagenes/renders/${id}/avances`, {
            img: img,
          });

          if (re) return true;
        } catch (error) {
          console.warn(error);
          return false;
        }
      },
      DELETE_RENDERS: async (id, img) => {
        try {
          const re = await putData(`/api/imagenes/renders/${id}/rendersweb`, {
            img: img,
          });
          if (re) return true;
        } catch (error) {
          console.warn(error);
          return false;
        }
      },
    },
    files: {
      GET_PLANO: async (id) => {
        try {
          const response = await getData(`/api/imagenes/media/${id}/planos`);
          if (response) return response;
        } catch (error) {
          return [];
        }
      },
      ADD_PLANO: async (obj) => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        };
        try {
          return await fetch(REACT_APP_API_URL_IMAGES, options);
        } catch (error) {
          console.warn(error);
        }
      },
    },
  },
  localidad: {
    GET_TODO: async () => {
      try {
        const response = await getData(`/api/localidades`);
        if (response) return response;
      } catch (error) {
        console.warn(error);
        return [];
      }
    },
    ADD: async (newLoc) => {
      try {
        const response = await putData(`/api/localidades`, newLoc);
        return response;
      } catch (error) {
        console.warn(error);
        return false;
      }
    },
    UPD_LOC: async (obj) => {
      try {
        const response = await postData(`/api/localidades`, obj);
        return response;
      } catch (error) {
        console.warn(error);
        return false;
      }
    },
  },
  home: {
    GET_SLIDEHOME: async () => {
      try {
        const response = await getData(`/api/home/slider`);
        if (response) return response;
      } catch (error) {
        console.warn(error);
        return [];
      }
    },
    GET_NOSOTROS: async () => {
      try {
        const response = await getData(`/api/home/nosotros`);
        if (response) return response;
      } catch (error) {
        console.warn(error);
        return [];
      }
    },
    GET_RENDERMOB: async () => {
      const response = await getData(`/api/home/sliderMobile`);
      if (response) return response;
      return [];
    },
    ADD_SLIDEHOME: async (obj) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };
      try {
        return await fetch(REACT_APP_API_URL_IMAGES, options);
      } catch (error) {
        console.warn(error);
      }
    },
    ADD_NOSOTROS: async (obj) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };
      try {
        const re = await fetch(REACT_APP_API_URL_IMAGES, options);
        return re;
      } catch (error) {
        console.warn(error);
      }
    },
    ADD_RENDERMOB: async (obj) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };
      try {
        const re = await fetch(REACT_APP_API_URL_IMAGES, options);
        return re;
      } catch (error) {
        console.warn(error);
      }
    },
    DELETE_SLIDEHOME: async (img) => {
      try {
        const re = await putData(`/api/home/slider`, {
          img: img,
        });
        if (re) return true;
      } catch (error) {
        console.warn(error);
        return false;
      }

      return false;
    },
    DELETE_NOSOTROS: async (img) => {
      try {
        const re = await putData(`/api/home/nosotros`, {
          img: img,
        });
        if (re) return true;
      } catch (error) {
        console.warn(error);
        return false;
      }
    },
    DELETE_RENDERMOB: async (img) => {
      try {
        const re = await putData(`/api/home/sliderMobile`, {
          img: img,
        });
        if (re) return true;
      } catch (error) {
        console.warn(error);
        return false;
      }
    },
  },
};

export default APIConsultas;
