
import { DataGrid, esES, gridClasses } from "@mui/x-data-grid";
import { CustomNoRowsOverlay, CustomPagination, QuickSearchToolbar } from "./templates";
import { Box, alpha, styled } from "@mui/material";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => (
  {
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[100],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[0],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  }
}));

/*
  *** props vars ***
  rows: any[];
  columns: any[];
  loading: boolean;
  useToolbar: boolean;
*/
export default function GridApp(props) {
  const setToolbar = () => {
    if (!props.useToolbar) {
      return null;
    }
    return QuickSearchToolbar;
  };
  return (
    <Box sx={{display: 'grid', gridTemplateColumns: '1fr', minHeight: '400px'}} className="bg-white">
      <StripedDataGrid
        className="h-full rdg-light rounded-[20px]"
        density="compact"
        rows={props.rows}
        columns={props.columns}
        getRowId={(row) => row[props.rowId]}
        loading={props.loading}
        slots={{
          toolbar: setToolbar(),
          pagination: CustomPagination,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </Box>
  );
}
