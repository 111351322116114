import Edificios from "./components/edificios/edificios";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Edit from "./components/edificios/Edit";
import Header from "./components/header";
import Localidades from "./components/Localidades/Localidades.jsx";
import Home from "./components/home/Home";
import Session from "./components/ctrlSession";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Session>
          <Header />
          <Routes>
            <Route path="/" element={<Navigate to="/edificios" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/edificios" element={<Edificios />} />
            <Route path="/edificios/:id" element={<Edit />} />
            <Route path="/localidades" element={<Localidades />} />
          </Routes>
        </Session>
      </BrowserRouter>
    </div>
  );
}

export default App;
