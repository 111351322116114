import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import APIConsultas from "../../services/consultas";
import LoadingButton from "../utils/loadingButton";

const CrearLocalidad = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newLoc, setNewLoc] = useState({
    nombre: "",
    direccion: "",
    telefono: "",
  });

  const onChange = (e) => {
    setNewLoc({
      ...newLoc,
      [e.target.name]: e.target.value,
    });
  };

  const createLocalidad = async (e) => {
    e.preventDefault();
    if (newLoc.nombre.length > 0) {
      setLoading(true);
      const response = await APIConsultas.localidad.ADD(newLoc);
      if (response.affectedRows > 0) {
        toast.success("Campo actualizado!");
        setOpenModal(false);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <button
        className="block text-[20px]  text-white  bg-primary-500 hover:bg-primary-400 transition-all focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2 text-center"
        type="button"
        data-modal-toggle="defaultModal"
        onClick={() => setOpenModal(true)}
      >
        Nueva Localidad
      </button>

      {openModal && (
        <div
          id="authentication-modal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex bg-[#00000099]"
          aria-modal="true"
          role="dialog"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                data-modal-toggle="authentication-modal"
                onClick={() => setOpenModal(false)}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 ">
                  Nueva Localidad
                </h3>
                <form className="space-y-6" onSubmit={createLocalidad}>
                  <div>
                    <label
                      htmlFor="nombre"
                      className="block text-start  mb-2 text-sm font-medium text-gray-900 "
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="nombre"
                      id="nombre"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                      placeholder="Ingrese nombre..."
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="direccion"
                      className="block text-start  mb-2 text-sm font-medium text-gray-900 "
                    >
                      Direccion
                    </label>
                    <input
                      type="text"
                      name="direccion"
                      id="direccion"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                      placeholder="Ingrese direccion..."
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="telefono"
                      className="block mb-2 text-start  text-sm font-medium text-gray-900 "
                    >
                      Telefono
                    </label>
                    <input
                      type="number"
                      name="telefono"
                      id="telefono"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                      placeholder="Ingrese telefono..."
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div>
                    <LoadingButton
                      onClick={() => null}
                      title="Guardar"
                      id="buttonCrearLocalidad"
                      type="submit"
                      loading={loading}
                    />
                  </div>
                  {/* <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={(e) => createLocalidad(e)}
                  >
                    Guardar
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default CrearLocalidad;
