import React from 'react'
import NosotrosHome from './NosotrosHome';
import RenderHome from "./RenderHome";
import RenderMob from './RenderMob';

const Home = () => {
  
  return (
    // <div className="w-full px-[5rem] mt-6">
      <div className="w-full p-[2rem] h-full min-h-[85vh]   shadow-md">
        <RenderHome />
        <RenderMob/>
        <NosotrosHome />
      </div>
    // </div>
  );
};

export default Home;
