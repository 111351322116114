import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SESSION_SET } from "../Redux/actions";
import localStorage from "../utils/localStorage";
import Login from "./login";

const Session = (props) => {
  const dispatch = useDispatch();
  const [session, setSession] = useState(null);
  const sessionState = useSelector((s) => s.session);
  const sessionLocal = localStorage.getFromStorage('session');
  useEffect(() => {
    if(sessionState) return setSession(true);
      setSession(false);
      if(sessionLocal) dispatch(SESSION_SET(sessionLocal))
  }, [dispatch, sessionLocal, sessionState]);

  

  return <>{session ? <div>{props.children}</div> : <Login />}</>;
};

export default Session;
