import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import APIConsultas from "../../services/consultas";

const Planos = ({ id, planos, setPlanos }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const data = await APIConsultas.edificio.files.GET_PLANO(id);
      setPlanos(data);
    };
    fetchData();
  }, [id]);

  const onFileChange = () => (ev) => {
    setLoading(true);
    if (ev.target.files) {
      // tslint:disable-next-line:forin
      for (const x in ev.target.files) {
        if (Object.prototype.hasOwnProperty.call(ev.target.files, x)) {
          const reader = new FileReader();
          reader.onload = (event1) => {
            setImageServer(event1.target.result);
          };
          if (x !== "length" && x !== "item") {
            reader.readAsDataURL(ev.target.files[x]);
          }
        }
      }
    }

    async function setImageServer(image) {
      const arrim = [image];
      setPlanos(arrim);
      const obj = {
        index: "add_file",
        file: image,
        filename: "planos.pdf",
        path: `/GF/File/proyects/${id}/`,
      };
      const re = await APIConsultas.edificio.files.ADD_PLANO(obj);
      if (re) {
        setLoading(false);
        return toast.success(`Dato actualizado!`, {
          autoClose: 1000,
        });
      }
      return toast.error(`Error al agregar imagen.`);
    }
  };
  return (
    <div className="w-[50%] px-3">
      <h1 className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        Planos
      </h1>
      <div className="border-2 border-blue-500  rounded-lg p-1 h-[47px] bg-gray-200">
        <div className="btn-addimg flex justify-center relative cursor-pointer ">
          <label
            className="btnimage block h-6 w-full absolute  top-1"
            title="Agregar imagen render"
            id="rendersWeb"
          >
            <div className="flex items-center gap-1 w-full cursor-pointer ">
              <i className="bx bx-image-add  text-2xl text-blue-500 hover:text-blue-700"></i>
            </div>
            {loading && (
              <div className="absolute top-[0.4rem] left-3 right-0 bottom-0 m-auto">
                <p className=" text-xs">Subiendo planos...</p>
              </div>
            )}
            <input
              type="file"
              //   accept="image/*,.pdf"
              name="Imgcarga"
              onChange={onFileChange()}
              multiple
              id="rendersWeb"
              className="hidden"
            />
          </label>
        </div>
      </div>
      <div className="overflow-x-auto flex w-full  max-h-[300px] items-center border-transparent border-l-2 relative">
        <div className="flex flex-col w-[100%]">
          {planos.length > 0 ? (
            <div className="flex   items-center p-1   hover:bg-primary-100 cursor-pointer">
              <i className="bx bx-check text-green-700 text-[30px]"></i>
              <p>planos.pdf</p>
            </div>
          ) : (
            <div className="flex   items-center p-1   hover:bg-primary-100 cursor-pointer">
              <i className="bx bx-x text-red-700 text-[30px]"></i>
              <p>planos.pdf</p>
            </div>
          )}
        </div>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default Planos;
