import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import APIConsultas from "../../services/consultas";

const FormImg = ({ id, arrRender, setArrRender }) => {
  const [cortes, setCortes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const nombres = [];
    let corte = "";
    if (arrRender) {
      arrRender.forEach((url) => {
        if (url.archivo) {
          corte = url.archivo.split("/");
        } else {
          corte = url.split("/");
        }
        nombres.push(corte[8]);
      });
      setCortes(nombres);
    }
  }, [arrRender]);

  const deleteImage = async (img, indeximg) => {
    Swal.fire({
      title: "Desea eliminar la imagen ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await APIConsultas.edificio.imagenes.DELETE_RENDERS(
          id,
          img
        );
        if (response) {
          const arrim = [...arrRender];
          arrim.splice(indeximg, 1);
          setArrRender(arrim);
          return toast.success(`Dato actualizado!`, {
            autoClose: 1000,
          });
        }
        return toast.error(`Error al eliminar imagen`);
      }
    });
  };
  const onFileChange = () => (ev) => {
    setLoading(true);
    if (!ev.target.files) return;
    const arrfiles = ev.target.files;

    for (const file in arrfiles) {
      if ((file.size / 1024).toFixed(2) > 200) {
        return toast.error(`Imagen demasiado pesada.`);
      }
      if (Object.prototype.hasOwnProperty.call(arrfiles, file)) {
        const element = ev.target.files[file];
        const reader = new FileReader();
        reader.onload = (event1) => {
          if (file !== "length" && file !== "item") {
            const obj = {
              titulo: "",
              archivo: event1.target.result,
              url: event1.target.result,
              indice: "",
              progress: 0,
              descripcion: "",
            };

            const arrim = [...arrRender];
            arrim.unshift(obj);
            setArrRender(arrim);
            return setImageServer(obj, element);
          }
        };
        if (file !== "length" && file !== "item") {
          reader.readAsDataURL(ev.target.files[file]);
        }
      }
    }

    async function setImageServer(file, element) {
      const obj = {
        index: "add_file",
        file: file.archivo,
        filename: element.name,
        path: `/GF/File/proyects/${id}/rendersweb/`,
      };
      try {
        const response = await APIConsultas.edificio.imagenes.ADD_RENDERS(obj);
        if (response) {
          setLoading(false);
          return toast.success(`Dato actualizado!`, {
            autoClose: 1000,
          });
        } else {
          return toast.error(`Error al agregar imagen.`);
        }
      } catch (error) {
        console.error(error);
      }

      return toast.error(`Error al agregar imagen.`);
    }
  };
  // const onFileChange = () => (ev) => {
  //   if (!ev.target.files) return;
  //   const arrfiles = ev.target.files;

  //   for (const file of arrfiles) {
  //     if ((file.size / 1024).toFixed(2) > 200) {
  //       return toast.error(`Imagen demasiado pesada.`);
  //     }
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const image = document.createElement("img");
  //       image.onload = function () {
  //         // Resize the image
  //         const canvas = document.createElement("canvas");
  //         const max_size = 1620; // TODO : pull max size from a site config
  //         let width = image.width;
  //         let height = image.height;
  //         if (width <= max_size) {
  //           canvas.width = width;
  //           canvas.height = height;
  //           canvas
  //             .getContext("2d")
  //             .drawImage(
  //               image,
  //               0,
  //               0,
  //               width,
  //               height,
  //               0,
  //               0,
  //               canvas.width,
  //               canvas.height
  //             );
  //           return setImageServer(
  //             canvas.toDataURL("image/jpg", 0.7),
  //             file.name
  //           );
  //         }
  //         // 1820  x 834
  //         return toast.error(`Imagen demasiado grande.`);
  //       };
  //       image.src = e.target.result;
  //     };
  //     if (file !== "length" && file !== "item") {
  //       reader.readAsDataURL(file);
  //     }
  //   }

  //   async function setImageServer(file, filename) {
  //     const arrim = [...arrRender];
  //     arrim.unshift(file);
  //     setArrRender(arrim);
  //     const obj = {
  //       index: "add_file",
  //       file,
  //       filename,
  //       path: `/GF/File/proyects/${id}/rendersweb/`,
  //     };
  //     try {
  //       const response = await APIConsultas.edificio.imagenes.ADD_RENDERS(obj);
  //       if (response)
  //         return toast.success(`Dato actualizado!`, {
  //           autoClose: 1000,
  //         });
  //     } catch (error) {
  //       console.error(error);
  //     }

  //     return toast.error(`Error al agregar imagen.`);
  //   }
  // };

  return (
    <div className="w-[50%]">
      <h1 className="font-bold">Renders web</h1>
      <div className="border-2 border-grey rounded-lg p-1 ">
        <div className="btn-addimg flex justify-center relative cursor-pointer ">
          <label
            className="btnimage block h-6 w-auto absolute right-[20px] top-1"
            title="Agregar imagen render"
            id="rendersWeb"
          >
            <div className="flex items-center gap-1">
              <span className="text-[12px] text-red-700">Ancho max.: 1620</span>
              <i className="bx bx-image-add  text-2xl text-blue-500 hover:text-blue-700"></i>
            </div>
            <input
              type="file"
              accept="image/*"
              name="Imgcarga"
              onChange={onFileChange()}
              multiple
              id="rendersWeb"
              className="hidden"
            />
          </label>
        </div>
        <div className="overflow-x-auto min-h-[56px]  flex w-full mt-[3rem] overflow-y-scroll scrollbar-1 scrollbar-thumb-gray-500 scrollbar-track-gray-100 max-h-[300px] items-center border-transparent border-l-2 relative">
          <div className="flex flex-col w-[100%]">
            {loading && (
              <div className="absolute top-[-3rem] left-3 right-0 bottom-0 m-auto flex justify-center items-center">
                <i className="bx bx-loader bx-spin text-2xl"></i>
                <p>Cargando renders..</p>
              </div>
            )}

            {arrRender &&
              arrRender.map((img, indeximg) => (
                <div
                  key={indeximg}
                  className="flex   justify-between items-center p-1 hover:bg-primary-100 cursor-pointer"
                >
                  <img
                    src={img}
                    alt="A"
                    priority="false"
                    className="rounded w-[100px] h-[50px]"
                  />
                  <span>{cortes[indeximg]}</span>
                  <span
                    aria-hidden
                    className="material-icons-outlined z-10  top-[2px] right-[5px] text-sm cursor-pointer text-red-600"
                    title="Eliminar imagen "
                    onClick={() => deleteImage(img, indeximg)}
                  >
                    close
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default FormImg;
