import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CrearEdif from "./CrearEdif";
import APIConsultas from "../../services/consultas";
import GridApp from "../gridApp";

const Edificios = () => {
  const [rows_edif, setRows_edif] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const columns = [
    { field: "nombre", headerName: "Nombre", flex: 1 },
    { field: "descripcion", headerName: "Descripcion", flex: 1 },
    { field: "fecinic", headerName: "Fecha Inicio", flex: 1 },
    { field: "fecfin", headerName: "Fecha Fin", flex: 1 },
    { field: "direccion", headerName: "Direccion", flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          key={1}
          onClick={(e) => navigate(`/edificios/${params.row.idedif}`)}
          icon={
            <span className="material-icons-outlined text-green-500">draw</span>
          }
          label="Editar"
        />,
      ],
    },
  ];
  useEffect(() => {
    setLoading(true);
    dataFetch(150);
  }, []);

  const dataFetch = async (limit) => {
    const data = await APIConsultas.edificio.GET_TODO(limit);
    setRows_edif(data);
    setLoading(false);
  };


  return (
    <div className="px-[5rem] py-4">
      <div className="flex justify-between  items-center">
        <h1 className="font-bold uppercase text-primary-500 text-2xl text-start my-4">
          Edificios
        </h1>
        <CrearEdif />
      </div>
      <GridApp
        rows={rows_edif}
        columns={columns}
        loading={loading}
        useToolbar={true}
        rowId={"idedif"}
      />
    </div>
  );
};

export default Edificios;
