import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import APIConsultas from "../../services/consultas";
import LoadingButton from "../utils/loadingButton";

const CrearEdif = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localidades, setLocalidades] = useState();
  const [datosEdif, setDatosEdif] = useState({
    nombre: "",
    idloc: "1",
  });

  useEffect(() => {

    const fetchData = async () => {
      const data = await APIConsultas.localidad.GET_TODO();
      setLocalidades(data);
    };
    fetchData();
  }, []);

  const createEdificio = async (e) => {
    e.preventDefault();
    if (datosEdif.nombre.length > 0) {
      setLoading(true);
      const response = await APIConsultas.edificio.ADD(datosEdif);

      if (response.affectedRows > 0) {
        toast.success("Campo actualizado!");
        setOpen(false);
        navigate(`/edificios/${response.insertId}`);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <button
        className="block text-[20px]  text-white bg-primary-500 hover:bg-primary-400 transition-all focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2 text-center "
        type="button"
        data-modal-toggle="authentication-modal"
        onClick={() => setOpen(!open)}
      >
        Nuevo edificio
      </button>
      {open && (
        <div
          id="authentication-modal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex bg-[#00000099]"
          aria-modal="true"
          role="dialog"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                data-modal-toggle="authentication-modal"
                onClick={() => setOpen(false)}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 ">
                  Nuevo Edificio
                </h3>
                <form className="space-y-6" onSubmit={createEdificio}>
                  <div>
                    <label
                      htmlFor="nombre"
                      className="block text-start mb-2 text-sm font-medium text-gray-900 "
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="nombre"
                      id="nombre"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                      placeholder="Ingrese nombre..."
                      required
                      onChange={(e) =>
                        setDatosEdif({
                          ...datosEdif,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-state"
                      >
                        Localidad
                      </label>
                    </div>
                    <div className="relative">
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                        id="grid-state"
                        name="idloc"
                        onChange={(e) =>
                          setDatosEdif({
                            ...datosEdif,
                            [e.target.name]: e.target.value,
                          })
                        }
                      >
                        {localidades?.map((loc, index) => (
                          <option key={index} value={loc.idloc}>
                            {loc.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <LoadingButton
                      onClick={() => null}
                      title="Guardar"
                      id="buttonCrearEdificio"
                      type="submit"
                      loading={loading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default CrearEdif;
