

import localStorage from '../utils/localStorage'
const initialState = {
  carrito: [],
  productos: [],
  detalles: [],
  filtrado: [],
  // categorias: []
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SESSION_SET":
      localStorage.setToStorage('session', payload);
      return {
        ...state,
        session: payload,
      };

    default:
      return state;
  }
};


export default Reducer;
