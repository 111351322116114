

export const SESSION_SET = (payload) => async (dispatch) => {
    try {
      return dispatch({
        type: 'SESSION_SET',
        payload: payload
      });
    } catch (error) {
      console.error(error);
    }
  };


