import React, { useEffect } from "react";
import { useState } from "react";
import APIConsultas from "../../services/consultas";
import FormAvances from "./FormAvances";
import FormImg from "./FormImg";
import FormLogo from "./FormLogo";
import FormPortada from "./FormPortada";

const ModalImg = ({
  id,
  arrAvances,
  setArrAvances,
  arrRender,
  setArrRender,
  logo,
  setLogo,
  portada,
  setPortada,
}) => {
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    console.log("entro logo");
    const fetchData = async () => {
      const data = await APIConsultas.edificio.imagenes.GET_LOGO(id);
      setLogo(data);
    };
    fetchData();
  }, [id, setLogo]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await APIConsultas.edificio.imagenes.GET_RENDERS(id);
      setArrRender(data);
    };
    fetchData();
  }, [id, setArrRender]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await APIConsultas.edificio.imagenes.GET_PORTADA(id);
      setPortada(data);
    };
    fetchData();
  }, [id, setPortada]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await APIConsultas.edificio.imagenes.GET_AVANCES(id);
      setArrAvances(data);
    };
    fetchData();
  }, [id, setArrAvances]);
  return (
    <div>
      <button
        className="flex justify-between text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center   "
        type="button"
        data-modal-toggle="defaultModal"
        onClick={() => setOpenModal(true)}
      >
        <span className="material-icons text-[20px]">attach_file</span> Adjuntar
        archivos
      </button>

      {openModal && (
        <div
          id="defaultModal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex bg-[#00000099]"
          aria-modal="true"
          role="dialog"
        >
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow ">
              <div className="flex justify-between items-start p-4 rounded-t border-b ">
                <h3 className="text-xl font-semibold text-gray-900 ">
                  Adjuntar archivos
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                  data-modal-toggle="defaultModal"
                  onClick={() => setOpenModal(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-6 space-y-6">
                <div className="flex justify-between gap-4">
                  <FormLogo id={id} logo={logo} setLogo={setLogo} />
                  <FormPortada
                    id={id}
                    portada={portada}
                    setPortada={setPortada}
                  />
                </div>
                <div className="flex justify-between gap-4">
                  <FormAvances
                    id={id}
                    arrAvances={arrAvances}
                    setArrAvances={setArrAvances}
                  />
                  <FormImg
                    id={id}
                    arrRender={arrRender}
                    setArrRender={setArrRender}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalImg;
