import React from "react";
import logo from "../media/logo.png";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SESSION_SET } from "../Redux/actions";

const Header = (props) => {
  const dispatch = useDispatch();
  const logout = (ev) => {
    ev.preventDefault();
    dispatch(SESSION_SET(null));
  }
  return (
    <div className="flex bg-white w-full px-[5rem] justify-between items-center shadow-md">
      <div>
        <img src={logo} alt="logo" />
      </div>
      <nav>
        <ul className="flex text-lg uppercase font-medium gap-[3rem]">
          <li>
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive ? "text-primary-500 font-bold" : ""
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/edificios"
              className={({ isActive }) =>
                isActive ? "text-primary-500 font-bold" : ""
              }
            >
              Edificios
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/localidades"
              className={({ isActive }) =>
                isActive ? "text-primary-500 font-bold" : ""
              }
            >
              Localidades
            </NavLink>
          </li>
          <li>
            <button
             onClick={(ev) => {logout(ev)}}
            >
              SALIR
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
