import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import APIConsultas from "../../services/consultas";

const NosotrosHome = () => {
  const [arrNosotros, setArrNosotros] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await APIConsultas.home.GET_NOSOTROS();
      setArrNosotros(data);
    };
    fetchData();
  }, []);

  const deleteImage = async (img, indeximg) => {
    Swal.fire({
      title: "Desea eliminar la imagen ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await APIConsultas.home.DELETE_NOSOTROS(img);
        if (response) {
          const arrim = [...arrNosotros];
          arrim.splice(indeximg, 1);
          setArrNosotros(arrim);
          return toast.success(`Imagenes actualizadas!`, {
            autoClose: 1000,
          });
        }
        return toast.error(`Error al eliminar imagen`);
      }
    });
  };

  const onFileChange = () => (ev) => {
    if (!ev.target.files) return;
    const arrfiles = ev.target.files;

    for (const file in arrfiles) {
      if ((file.size / 1024).toFixed(2) > 200) {
        return toast.error(`Imagen demasiado pesada.`);
      }
      if (Object.prototype.hasOwnProperty.call(arrfiles, file)) {
        const element = ev.target.files[file];
        const reader = new FileReader();
        reader.onload = (event1) => {
          if (file !== "length" && file !== "item") {
            const obj = {
              titulo: "",
              archivo: event1.target.result,
              url: event1.target.result,
              indice: "",
              progress: 0,
              descripcion: "",
            };

            const arrim = [...arrNosotros];
            arrim.unshift(obj);
            setArrNosotros(arrim);
            return setImageServer(obj, element);
          }
        };
        if (file !== "length" && file !== "item") {
          reader.readAsDataURL(ev.target.files[file]);
        }
      }
    }

    async function setImageServer(file, element) {
      const obj = {
        index: "add_file",
        file: file.archivo,
        filename: element.name,
        path: "/GF/File/imgsweb/home/nosotros/",
      };
      try {
        const response = await APIConsultas.home.ADD_NOSOTROS(obj);
        if (response)
          return toast.success(`Dato actualizado!`, {
            autoClose: 1000,
          });
      } catch (error) {
        console.error(error);
      }

      return toast.error(`Error al agregar imagen.`);
    }
  };

  // const onFileChange = () => (ev) => {
  //   if (!ev.target.files) return;
  //   const arrfiles = ev.target.files;

  //   for (const file of arrfiles) {
  //     if ((file.size / 1024).toFixed(2) > 200) {
  //       return toast.error(`Imagen demasiado pesada.`);
  //     }
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const image = document.createElement("img");
  //       image.onload = function () {
  //         // Resize the image
  //         const canvas = document.createElement("canvas");
  //         const max_size = 1820; // TODO : pull max size from a site config
  //         let width = image.width;
  //         let height = image.height;
  //         if (width === max_size) {
  //           canvas.width = width;
  //           canvas.height = height;
  //           canvas
  //             .getContext("2d")
  //             .drawImage(
  //               image,
  //               0,
  //               0,
  //               width,
  //               height,
  //               0,
  //               0,
  //               canvas.width,
  //               canvas.height
  //             );
  //           return setImageServer(
  //             canvas.toDataURL("image/jpg", 0.7),
  //             file.name
  //           );
  //         }
  //         // 1820  x 834
  //         return toast.error(`Imagen demasiado grande.`);
  //       };
  //       image.src = e.target.result;
  //     };
  //     if (file !== "length" && file !== "item") {
  //       reader.readAsDataURL(file);
  //     }
  //   }

  //   async function setImageServer(file, filename) {
  //     const arrim = [arrNosotros];
  //     arrim.unshift(file);
  //     setArrNosotros(arrim);
  //     const obj = {
  //       index: "add_file",
  //       file,
  //       filename,
  //       path: "/GF/File/imgsweb/home/nosotros/",
  //     };
  //     try {
  //       const response = await APIConsultas.home.ADD_NOSOTROS(obj);
  //       if (response)
  //         return toast.success(`Dato actualizado!`, {
  //           autoClose: 1000,
  //         });
  //     } catch (error) {
  //       console.error(error);
  //     }

  //     return toast.error(`Error al agregar imagen.`);
  //   }
  // };

  return (
    <div>
      <h1 className="font-medium uppercase text-primary-500 text-2xl text-start my-4">
        Nosotros Home
      </h1>
      <div className="py-3 mb-12 bg-white rounded-2xl shadow-md">
        <div className="btn-addimg flex justify-center relative cursor-pointer ">
          <label
            className="btnimage block h-auto w-auto absolute left-4 top-1"
            title="Agregar imagen render"
            id="rendersWeb"
          >
            <div className="flex items-center justify-center gap-3">
              <span className="flex w-[200px] bg-primary-500 hover:bg-blue-700 text-white items-center justify-center py-[0.2rem] rounded-lg cursor-pointer">
                <i className="bx bx-image-add  text-xl "></i>
                <p>Agregar Imagen</p>
              </span>
              <h2>Peso maximo aceptado : 200KB</h2>
            </div>
            <input
              type="file"
              accept="image/*"
              name="Imgcarga"
              onChange={onFileChange()}
              multiple
              id="rendersWeb"
              className="hidden"
            />
          </label>
        </div>
        <div className="overflow-x-scroll min-h-[56px]  mt-[3rem]  items-center border-transparent border-l-2">
          <div className="flex  w-[100%] ">
            {arrNosotros &&
              arrNosotros.map((img, indeximg) => (
                // console.log(img),
                <div
                  key={indeximg}
                  className=" w-auto  p-1 hover:bg-primary-100 cursor-pointer relative "
                >
                  <img
                    src={img}
                    alt="A"
                    className="rounded min-w-[400px] max-h-[250px] object-cover "
                  />
                  <span
                    aria-hidden
                    className="material-icons-outlined z-10  absolute top-[10px] right-[10px] text-[30px] cursor-pointer text-red-600"
                    title="Eliminar imagen"
                    onClick={() => deleteImage(img, indeximg)}
                  >
                    close
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </div>
  );
};

export default NosotrosHome;
