import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ModalImg from "../imagenes/ModalImg";
import Planos from "../imagenes/planos";
import APIConsultas from "../../services/consultas";

const Edit = () => {
  const [datos, setDatos] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [arrRender, setArrRender] = useState([]);
  const [arrAvances, setArrAvances] = useState([]);
  const [logo, setLogo] = useState([]);
  const [portada, setPortada] = useState([]);
  const [planos, setPlanos] = useState([]);
  const [timer, setTimer] = useState(null);

  const [localidades, setLocalidades] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await APIConsultas.edificio.GET_XID(id);
      setDatos(data);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchLocalidades = async () => {
      const data = await APIConsultas.localidad.GET_TODO();
      setLocalidades(data);
    };
    fetchLocalidades();
  }, []);

  const onChange = async (e) => {
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        const obj = {
          id: datos.idedif,
          target: e.target.value,
          name: e.target.name,
        };
        const response = await APIConsultas.edificio.UPDATE_XCAMPO(obj);
        if (response.affectedRows > 0) {
          toast.success("Campo actualizado!");
        } else {
          toast.error("Hubo un error!");
        }
      }, 1000)
    );
  };
  return (
    <form className="w-full px-[4rem] py-[2rem]   shadow-md">
      <h1 className="text-[35px] font-bold text-primary-500 text-start">
        Editar {datos.nombre}
      </h1>
      <div className="flex justify-between">
        <div className="flex flex-wrap -mx-3 py-3 bg-white rounded-2xl shadow-md w-[50%] p-4  ">
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className=" text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Nombre
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="text"
              name="nombre"
              defaultValue={datos.nombre}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left"
              htmlFor="grid-state"
            >
              Estado
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-200 border border-blue-500 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                name="idestado"
                value={datos.idestado}
                onChange={(e) => {
                  onChange(e);
                }}
              >
                <option value={1}>Disponible</option>
                <option value={2}>Vendidos</option>
              </select>
            </div>
          </div>

          <div className="w-full md:w-[50%]  px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Fecha de inicio
            </label>
            <input
              type="type"
              name="fecinic"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.fecinic}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%]  px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Fecha de finalizacion
            </label>
            <input
              type="type"
              name="fecfin"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.fecfin}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>

          <div className="w-full md:w-[100%]  px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Descripcion
            </label>
            <textarea
              type="text"
              name="descripcion"
              className="appearance-none block w-full md:h-[200px] bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.descripcion}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
        </div>

        <div className="flex flex-wrap bg-white rounded-2xl shadow-md -mx-3  w-[50%] p-4 ">
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Direccion
            </label>
            <input
              type="text"
              name="direccion"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.direccion}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Localidad
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-200 border border-blue-500 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                name="idloc"
                defaultValue="1"
                onChange={(e) => {
                  onChange(e);
                }}
              >
                {localidades?.map((l, i) => (
                  <option value={l.idloc} key={i}>
                    {l.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Niveles
            </label>
            <input
              type="number"
              name="niveles"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.niveles}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Unidades
            </label>
            <input
              type="number"
              name="unidades"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.unidades}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Un dormitorio
            </label>
            <input
              type="number"
              name="undorm"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.undorm}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Dos dormitorios
            </label>
            <input
              type="number"
              name="dosdorm"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.dosdorm}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Tres Dormitorios
            </label>
            <input
              type="number"
              name="tresdorm"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.tresdorm}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left text-leftblock uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Locales
            </label>
            <input
              type="number"
              name="locales"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.locales}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Oficinas
            </label>
            <input
              type="number"
              name="oficinas"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.oficinas}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Cocheras
            </label>
            <input
              type="number"
              name="cocheras"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.cocheras}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap bg-white rounded-2xl shadow-md -mx-3 mt-5 w-[50%] p-4 ">
          <div className="w-full md:w-[50%]  px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Url Imagen
            </label>
            <input
              type="text"
              name="urlimg"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.urlimg}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Url Video
            </label>
            <input
              type="text"
              name="urlvideo"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              defaultValue={datos.urlvideo}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
          <div className="w-full md:w-[50%] px-3 mb-6 md:mb-0">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Muestra Web
            </label>
            <select
              className="block appearance-none w-full bg-gray-200 border border-blue-500 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
              name="muestraweb"
              defaultValue={datos.muestraweb}
              onChange={(e) => {
                onChange(e);
              }}
            >
              <option value="">--Seleccionar--</option>
              <option value={1}>Mostrar</option>
              <option value={0}>No mostrar</option>
            </select>
          </div>
          <Planos id={id} planos={planos} setPlanos={setPlanos} />
        </div>
        <div className="flex flex-wrap bg-white rounded-2xl shadow-md -mx-3 mt-5 flex-col w-[50%] items-center px-3 p-4 ">
          <label className="text-left w-full uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 flex">
            Imagenes
          </label>
          <div className=" flex flex-wrap justify-between">
            <div className="relative mb-6 w-[48%]">
              <input
                type="text"
                id="input-group-1"
                className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pr-10 p-2.5   "
                value="Logo"
                disabled
                readOnly
              />
              <div className="flex absolute inset-y-0 right-1 items-center pl-3 pointer-events-none">
                {logo.length > 0 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className=" text-green-600 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className="w-5 h-5 text-gray-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                  </svg>
                )}
              </div>
            </div>
            <div className="relative mb-6 w-[48%]">
              <input
                type="text"
                id="input-group-1"
                className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pr-10 p-2.5      "
                value="Slider"
                disabled
                readOnly
              />
              <div className="flex absolute inset-y-0 right-1 items-center pl-3 pointer-events-none">
                {arrRender.length > 0 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className=" text-green-600 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className="w-5 h-5 text-gray-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                  </svg>
                )}
              </div>
            </div>

            <div className="relative mb-6 w-[48%]">
              <input
                type="text"
                id="input-group-1"
                className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pr-10 p-2.5      "
                value="Portada"
                disabled
                readOnly
              />
              <div className="flex absolute inset-y-0 right-1 items-center pl-3 pointer-events-none">
                {portada.length > 0 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className=" text-green-600 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className="w-5 h-5 text-gray-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                  </svg>
                )}
              </div>
            </div>
            <div className="relative mb-6 w-[48%]">
              <input
                type="text"
                id="input-group-1"
                className="bg-gray-200 border cursor-not-allowed focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pr-10 p-2.5      "
                value="Avance de obras"
                disabled
                readOnly
              />
              <div className="flex absolute inset-y-0 right-1 items-center pl-3 pointer-events-none">
                {arrAvances.length > 0 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className=" text-green-600 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className="w-5 h-5 text-gray-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                  </svg>
                )}
              </div>
            </div>
          </div>
          <div className="w-full text-left">
            <ModalImg
              id={id}
              arrRender={arrRender}
              setArrRender={setArrRender}
              arrAvances={arrAvances}
              setArrAvances={setArrAvances}
              logo={logo}
              setLogo={setLogo}
              portada={portada}
              setPortada={setPortada}
            />
          </div>
        </div>
      </div>
      <div className="md:flex md:items-center justify-end w-full">
        <button
          className="shadow text-white bg-primary-500 hover:bg-primary-400 transition-all font-bold py-2 px-16 rounded cursor-pointer text-[20px] mt-4"
          type="button"
          onClick={(e) => navigate(`/edificios`)}
        >
          Volver al formulario
        </button>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            border: "2px solid black",
            paddingLeft: "50px",
            paddingRight: "50px",
            fontSize: "20px",
          },
        }}
      />
    </form>
    // </div>
  );
};

export default Edit;
